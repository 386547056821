import React, { useEffect, useState } from "react";
import moment from 'moment';
import PanelTemplate from "../../../templates/Panel";
import Table from "../../../components/Table";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { renderError } from "../../../helpers/errors";
import BoxTabs from "../../../components/BoxTabs";
import InputSelect from "../../../components/InputSelect";
import { createOptions } from "../../../helpers/selects";
import PageHeader from "../../../components/PageHeader";
import Button from "../../../components/Button";
import { toast } from "react-toastify";


export const CompanyCategoryList = () => {
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [updatingProducts, setUpdatingProducts] = useState(false);
    const [companyCategories, setCompanyCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const navigate = useNavigate();

    useEffect(() => {
        getCategories();
        getCompanyCategories();
    }, []);

    useEffect(() => {
        generateData();
    }, [companyCategories, updating]);

    const getCategories = () => {
        setUpdating(true);

        api.get(`/category`).then(res => {
            let toCategories = [];
            res.data.resources.map(category => {
                toCategories.push(category);
                category.categories.map(subcategory => toCategories.push({
                    ...subcategory,
                    name: `${category.name} > ${subcategory.name}`
                }));
            });
            setCategories(toCategories);
        }).catch(error => {
            renderError(error);
        }).then(() => setUpdating(false));
    }

    const updateCategory = (companyCategory, categoryId) => {
        api.put(`/company-category/${companyCategory.id}`, {
            category_id: categoryId
        }).then(res => {
            let toCompanyCategories = [];
    
            companyCategories.map(currentCompanyCategory => {
                toCompanyCategories.push({
                    ...currentCompanyCategory,
                    category_id: currentCompanyCategory.id === companyCategory.id ? categoryId : currentCompanyCategory.category_id
                })
            });
    
            setCompanyCategories(toCompanyCategories);
        }).catch(error => renderError(error));
    }

    const generateData = () => {
        const header = ['Categoria fornecedor', 'Categoria interna', 'Última atualização', 'Cadastrada em'];
        const rows = {};
        
        ['asia', 'xbz', 'spot', 'outros'].map(type => {
            rows[type] = [];
            companyCategories.map(companyCategory => {
                if(companyCategory.company_integration !== type) return;

                rows[type].push({
                    data: [
                        `${companyCategory.company_category_name}${companyCategory.company_subcategory_name ? ` > ${companyCategory.company_subcategory_name}` : ''}`,
                        <InputSelect 
                            loading={updating} 
                            value={companyCategory.category_id} 
                            change={categoryId => updateCategory(companyCategory, categoryId)} 
                            options={createOptions(categories, 'name', 'id', true)}
                            removeMargin={true}
                        />,
                        moment(companyCategory.updated_at).format('DD/MM/YYYY HH:mm'),
                        moment(companyCategory.created_at).format('DD/MM/YYYY HH:mm')
                    ],
                    action: () => null
                })
            });
        })

        setData({header, rows});
    }

    const getCompanyCategories = () => {
        setLoading(true);

        api.get(`/company-category`).then(res => {
            setCompanyCategories(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateProducts = company => {
        setUpdatingProducts(true);
        api.put(`/company-category/update-product/${company}`).then(res => {
            toast.success('Produtos atualizados');
        }).catch(error => {
            renderError(error);
        }).then(() => setUpdatingProducts(false));
    }

    return (
        <PanelTemplate id="company-categories-list">
            {/* <PageHeader
                right={
                    <Button
                        type={`primary`} 
                        size={`small`} 
                        text={`Atualizar produtos`} 
                        svg="plus-white" 
                        action={updateProducts}  
                    />
                }
            /> */}
            <BoxTabs
                titles={[
                    'ASIA IMPORTS',
                    'SPOT',
                    'XBZ',
                    'Importação antigos'
                ]}
                contents={[
                    <>
                        <Button
                            type={`primary`} 
                            size={`small`} 
                            text={`Atualizar produtos`} 
                            svg="update-white" 
                            loading={updatingProducts}
                            action={() => updateProducts('asia')}  
                        />
                        <br />
                        <Table header={data.header} rows={data.rows.asia} loading={loading} />
                    </>,
                    <>
                        <Button
                            type={`primary`} 
                            size={`small`} 
                            text={`Atualizar produtos`} 
                            svg="update-white" 
                            loading={updatingProducts}
                            action={() => updateProducts('spot')}  
                        />
                        <br />
                        <Table header={data.header} rows={data.rows.spot} loading={loading} />
                    </>,
                    <>
                        <Button
                            type={`primary`} 
                            size={`small`} 
                            text={`Atualizar produtos`} 
                            svg="update-white" 
                            loading={updatingProducts}
                            action={() => updateProducts('xbz')}  
                        />
                        <br />
                        <Table header={data.header} rows={data.rows.xbz} loading={loading} />
                    </>,
                    <>
                        <Button
                            type={`primary`} 
                            size={`small`} 
                            text={`Atualizar produtos`} 
                            svg="update-white" 
                            loading={updatingProducts}
                            action={() => updateProducts('outros')}  
                        />
                        <br />
                        <Table header={data.header} rows={data.rows.outros} loading={loading} />
                    </>
                ]}
            />
        </PanelTemplate>
    );
}

export default CompanyCategoryList;