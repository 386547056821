import React from 'react';
import { BrowserRouter, Navigate, Route, Routes as ReactRouterRoutes, useLocation } from "react-router-dom";

import isAuthenticated from '../services/auth';

import Login from '../pages/Login';
import PasswordRecovery from '../pages/PasswordRecovery';
import privateRoutes, { getInitialPath } from './privateRoutes';

const Routes = () => {
    const RequireAuth = ({children, route}) => {
        let location = useLocation();
        
        if(!isAuthenticated()){
            return <Navigate to="/login" state={{ from: location }} />;
        }

        if(!route.show){
            let initialPath = getInitialPath();
            return <Navigate to={initialPath} state={{ from: location }} />;
        }

        return children;
    };

    return (
        <BrowserRouter>
            <ReactRouterRoutes>
                <Route exact path="/" element={isAuthenticated ? <Navigate to="/quote/list" /> : <Navigate to="/login" /> } />
                <Route path="/login" element={<Login />} />
                <Route path="/password-recovery" element={<PasswordRecovery />} />

                {privateRoutes.map((privateRoute, index) => (
                    <Route key={index} path={privateRoute.path} element={<RequireAuth route={privateRoute}>{privateRoute.element}</RequireAuth>} />
                ))}
            </ReactRouterRoutes>
        </BrowserRouter>
    );
}

export default Routes;