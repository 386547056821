import moment from "moment";
import 'moment/locale/pt-br';

moment.locale('pt-br');

export const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export const getBase64Promise = file => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const cropFileName = str => {
    if(typeof str !== 'string') return str;
    if(str.length <= 15) return str;
    return `...${str.slice(-15)}`;
}

export const splitArray = (arr, n, current) => {
    let total = arr.length;
    let limit = Math.ceil(total / n);
    let count = 0;
    let position = 0;
    let res = [];

    arr.map(obj => {
        if(!res[position]) res[position] = [];
        res[position].push(obj);
        count++;
        if(count >= limit){
            count = 0;
            position++;
        }
    });

    if(res[current]){
        return res[current];
    } else {
        return [];
    }
}

export const convertMoenyToInt = str => {
    if(!str) return 0;
    if(!isNaN(str)) return str;
    let v = str.replace(/\D/g,'');
    v = parseInt(v);
    v = isNaN(v) ? 0 : v;
    return v;
}

export const convertIntToMoney = (int, symbol = false) => {
    let value = int / 100;
    value = isNaN(value) ? 0 : value;
    let positive = value >= 0;
    value = Math.abs(value);
    let res = value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    res = symbol ? `R$ ${res}` : res;
    res = positive ? res : `- ${res}`;
    return res;
}

export const convertWeightToInt = str => {
    if(!str) return 0;
    if(!isNaN(str)) return str;
    let v = str.replace(/\D/g,'');
    v = parseInt(v);
    v = isNaN(v) ? 0 : v;
    return v;
}

export const convertDimensionsToInt = str => {
    if(!str) return 0;
    if(!isNaN(str)) return str;
    let v = str.replace(/\D/g,'');
    v = parseInt(v);
    v = isNaN(v) ? 0 : v;
    return v;
}


export const convertPercentageToFloat = str => {
    let v = str.replace(/\D/g,'');
    v = parseInt(v);
    v = isNaN(v) ? 0 : v;
    v = v / 1000000;
    return v;
}

export const convertPercentageToInt = str => {
    let isNegative = str.includes('-');
    let v = str.replace(/\D/g, '');
    v = parseInt(v);
    v = isNaN(v) ? 0 : v;
    return isNegative ? -v : v;
}

export const convertIntToPercentage = int => {
	let v = (int/10000).toFixed(4) + '';
    v = v.replace(".", ",");
	return `${v} %`;
}

export const convertDateToRelativeTime = (date, withHour = false) => {
    let format = 'DD/MM/YYYY';
    format = withHour ? `${format} [às] HH[h]mm` : format;
    const formattedDate = moment(date).format(format);
    const relativeTime = moment(date).fromNow();
    return `${formattedDate} - ${relativeTime}`;
}